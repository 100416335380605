import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useCustomModal} from "./custom-message-modal";
import CloseIcon from '@mui/icons-material/Close';
import {Box, Button, IconButton, Typography} from "@mui/material";
import {CSVLink} from "react-csv";
import {DataGridPro, GridColDef, GridColumnHeaderParams} from "@mui/x-data-grid-pro";
import {utils, write, writeFile} from "xlsx";
import {DataGrid} from "@mui/x-data-grid";
import {uploadFileUsingS3} from "../../components/file-management";
import {showSnackbar} from "../../redux/snackbar";

//This asks the user to choose a match category for the mark they just added.
const DockLockConsentModal = (props : { onExport: () => void,onSave: (file: File)=>void, data: any }) => {
    const { hideModal } = useCustomModal();
    const modalRef = useRef<HTMLDivElement>(null);
    const columns = useMemo(()=>{
        return props.data.columns.map((header: any, index: number) => ({
            field: header,
            headerName: header,
            width: index === (props.data.columns.length - 1) ? 100 : 439,
            renderHeader: (params: GridColumnHeaderParams) => <strong>{header}</strong>
        }))
    },[props.data.columns]);

    const memoizeRows = useMemo(()=>{
        return props.data.rows.map((row: any, index: number) => ({
            ...row,
            id: index
        }))
    },[props.data.rows]);

    const getOutputWorkbook = () => {
        let ws = utils.json_to_sheet(props.data.rows)
        let wb = utils.book_new()

        utils.book_append_sheet(wb, ws, "Sheet0")
        return wb;
    }

    const exportXLSX = () => {
        let wb = getOutputWorkbook();
        writeFile(wb, getFileName())
    }

    const getFileName = () => {
        const date = new Date().toLocaleString().replaceAll("/", "-").replaceAll(",", "").replaceAll(" ", "_");
        const extension = '.xlsx';
        return props.data.columns[0] + '->' +  props.data.columns[1] + '-' + date + extension;
    }

    const saveToFileManagement = () => {
        let wb = getOutputWorkbook();
        const fileData = write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([fileData], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        const file = new File([blob], getFileName(), {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        props.onSave(file);
    }


    return (<div ref={modalRef} style={{position: "relative", padding: '0px 10px'}} >
        <div style={{width: '980px'}}>
            <header style={{ fontSize: 30, marginBottom: 10,marginTop: '10px' }}>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Typography variant="h5">Compare Change List</Typography>
                    <IconButton aria-label="delete" onClick={()=>hideModal()}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </header>
            <Box marginBottom={2}>
                <Button disabled={memoizeRows.length === 0} variant="contained" color="secondary" style={{marginRight: 10}} onClick={exportXLSX}>
                    Export Excel Data
                </Button>
                <Button disabled={memoizeRows.length === 0} variant="contained" color="secondary" style={{marginRight: 10}} onClick={saveToFileManagement} >
                    Save to File Management
                </Button>
            </Box>
            <Box>
                <DataGrid
                             autoHeight={true}
                             rows={memoizeRows}
                             columns={columns}
                             //@ts-ignore
                             keepColumnPositionIfDraggedOutside={true}
                             pagination={true}
                             pageSize={10}
                             getRowHeight={() => 'auto'}
                             sx={{
                                 '& .MuiDataGrid-cell': {
                                     borderRight: '1px solid rgba(224,224,224,1)',
                                     padding: '10px',
                                 },
                                 '& .MuiDataGrid-columnSeparator': {
                                     visibility: 'visible'
                                 }
                             }}


                />
            </Box>
        </div>

    </div>)


};

export default DockLockConsentModal;
