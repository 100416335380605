import { useForm, Controller, SubmitHandler } from "react-hook-form";
import {
  TextField,
  Autocomplete,
  FormControl,
  Box,
  Button,
  Typography,
  Divider,
  createFilterOptions,
} from "@mui/material";
import { useCustomModal } from "../custom-message-modal";
import "./remove-entity-modal.scss";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hook";
import { showSnackbar } from "../../../redux/snackbar";
import { Project } from "../../../models/Project";
import { postApiTasksBulkLinkFilesToTask } from "../../../services/task";
import { postApiNewNlpRequest } from "../../../services/nlp";
import { StyledCloseIconUploader } from "../../../components/close-button";
import { TaskListSelectMenuItem } from "../../../components/TaskListSelectMenuItem";

import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { ErrorHelper } from "../../../components/error-helper";
import { userSettingsSelector } from "../../../redux/userSettings";
import { UserSettings } from "../../../models/UserSettings";

type FormData = {
  personalData: { id: string; description: string }[];
  confidentialData: { id: string; description: string }[];
  selectedPages: string | null;
  customSelectedPages?: string;
  dateTime: dayjs.Dayjs;
};

const defaultValues = {
  personalData: [],
  confidentialData: [],
  selectedPages: "All",
  customSelectedPages: "",
  dateTime: dayjs().add(2, "hour"),
};

const personalDataEntitiesToIdentify = [
  {
    id: "ae_mh_from_context",
    description: "Adverse Events and Medical History",
    type: "pd",
  },
  {
    id: "ae_mh_from_dictionary",
    description: "Adverse Events and Medical History (add dictionary)",
    type: "pd",
  },
  {
    id: "cm_from_dictionary",
    description: "Concomitant Medications from dictionary",
    type: "pd",
  },
  {
    id: "date",
    description: "Dates and times",
    type: "pd",
  },
  {
    id: "duration",
    description: "Durations",
    type: "pd",
  },
  {
    id: "id",
    description: "IDs",
    type: "pd",
  },
];

const confidentialInformationEntitiesToIdentify = [
  {
    id: "formulation",
    description: "Formulation",
    type: "pd",
  },
  {
    id: "manufacturing_method",
    description: "Manufacturing method",
    type: "pd",
  },
  {
    id: "dosing_regimen",
    description: "Dosing regimen",
    type: "pd",
  },
  {
    id: "time_points",
    description: "Time points",
    type: "pd",
  },
  {
    id: "sample_size",
    description: "Sample size",
    type: "pd",
  },
  {
    id: "exploratory_endpoint",
    description: "Exploratory endpoints",
    type: "pd",
  },
  {
    id: "vendors_third_party_affiliations",
    description: "Vendors/Third party affiliations",
    type: "pd",
  },
  {
    id: "non_clinical",
    description: "Non-clinical",
    type: "pd",
  },
  {
    id: "statistical_methods",
    description: "Statistical methods",
    type: "pd",
  },
  {
    id: "product_characteristics",
    description: "Product characteristics",
    type: "pd",
  },
  {
    id: "schedule_of_events",
    description: "Schedule of events",
    type: "pd",
  },
  {
    id: "biopharmaceutics",
    description: "Biopharmaceutics",
    type: "pd",
  },
  {
    id: "molecules",
    description: "Molecules",
    type: "pd",
  },
];

const filePagesOptions = ["All", "Customized"];

export function NlpSuggestAnnotations(
  project: Project,
  selectedFileIds: number[],
  refreshProject: () => void,
  reset: () => void
) {
  const dispatch = useAppDispatch();
  const userSettings: UserSettings = useAppSelector((state) =>
    userSettingsSelector(state)
  );

  const { control, handleSubmit, watch, setError, clearErrors, formState } =
    useForm<FormData>({
      defaultValues: {
        ...defaultValues,
      },
    });

  const { errors, isSubmitted } = formState;

  const selectedPages = watch("selectedPages");

  const { hideModal } = useCustomModal();

  const onSubmitForm: SubmitHandler<FormData> = async (data) => {
    const {
      personalData,
      confidentialData,
      dateTime,
      selectedPages,
      customSelectedPages,
    } = data;

    const utcDateTime =
      dateTime && dayjs.isDayjs(dateTime) ? dateTime.toISOString() : "";

    const isPersonalAndConfidentialDataValid =
      validatePersonalAndConfidentialData(personalData, confidentialData);

    const isCustomFilePagesValid = validateCustomFilePages(
      customSelectedPages || ""
    );

    if (!isPersonalAndConfidentialDataValid || !isCustomFilePagesValid) {
      return;
    }
    console.log("project", project);
    console.log("selectedFileIds", selectedFileIds);
    const requestPayload = {
      personalData,
      confidentialData,
      selectedPages: selectedPages === "All" ? null : customSelectedPages,
      sqsProcessingDataTime: utcDateTime,
      fileIds: selectedFileIds,
    };

    console.log("requestPayload", requestPayload);

    try {
      await postApiNewNlpRequest(requestPayload);
      dispatch(
        showSnackbar({
          message: `Successfully file added for annotation suggestions!`,
          type: "info",
        })
      );
      reset();
    } catch (error) {
      dispatch(
        showSnackbar({
          message: "Error file adding for annotation suggestions!",
          type: "error",
        })
      );
    } finally {
      hideModal();
      refreshProject();
    }
  };

  const validatePersonalAndConfidentialData = (
    personalData: { id: string; description: string }[],
    confidentialData: { id: string; description: string }[]
  ) => {
    if (personalData.length === 0 && confidentialData.length === 0) {
      setError("personalData", {
        type: "manual",
        message:
          "Please select either Personal Data or Confidential Information",
      });
      setError("confidentialData", {
        type: "manual",
        message:
          "Please select either Personal Data or Confidential Information",
      });
      return false;
    } else {
      clearErrors(["personalData", "confidentialData"]);
      return true;
    }
  };

  const validateCustomFilePages = (customSelectedPages: string) => {
    if (selectedPages === "Customized" && !customSelectedPages) {
      setError("customSelectedPages", {
        type: "manual",
        message: "Please enter pages to process",
      });
      return false;
    } else if (
      selectedPages === "Customized" &&
      !customSelectedPages.match(/^(\d+(-\d+)?)(,\s*\d+(-\d+)?)*$/)
    ) {
      setError("customSelectedPages", {
        type: "manual",
        message: "Invalid format. Use e.g. 1-3, 5, 7-10",
      });
      return false;
    } else {
      clearErrors(["customSelectedPages"]);
      return true;
    }
  };

  return (
    <div
      style={{ marginTop: 10, width: 500, maxHeight: "calc(100vh - 204px)" }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="wizard-title">
          <StyledCloseIconUploader onClick={hideModal} />
          <span>{"Select Annotation Categories"}</span>
        </div>
        <form style={{ marginBottom: 20 }}>
          <Box
            sx={{
              margin: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            {/* form field */}
            <FormControl fullWidth size="small" sx={{ gap: 1 }}>
              <Typography>Personal Data Entities to identify</Typography>
              <Controller
                name="personalData"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    size="small"
                    multiple
                    options={personalDataEntitiesToIdentify}
                    fullWidth
                    disableCloseOnSelect
                    filterSelectedOptions
                    freeSolo={false}
                    getOptionLabel={(option) => option.description}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    value={field.value}
                    onChange={(_, value, reason) => {
                      validatePersonalAndConfidentialData(
                        value,
                        watch("confidentialData")
                      );

                      if (value.some((v) => v.id === "selectAll")) {
                        field.onChange(personalDataEntitiesToIdentify);
                      } else {
                        field.onChange(value);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filter = createFilterOptions<{
                        id: string;
                        description: string;
                      }>();
                      const filtered = filter(options, params);
                      if (
                        field.value.length ===
                        personalDataEntitiesToIdentify.length
                      ) {
                        return [];
                      } else {
                        return [
                          { id: "selectAll", description: "Select All" },
                          ...filtered,
                        ];
                      }
                    }}
                    renderOption={(props, option, state) => (
                      <>
                        {option.id === "selectAll" ? (
                          <div key={option.id}>
                            <li {...props}>
                              {option.description} <Divider />
                            </li>
                          </div>
                        ) : (
                          <li key={option.id} {...props}>
                            {option.description}
                          </li>
                        )}
                      </>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label={"Personal Data"}
                        error={isSubmitted && !!errors.personalData}
                      />
                    )}
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth size="small" sx={{ gap: 1 }}>
              <Typography>
                Confidential Information Categories to identify
              </Typography>
              <Controller
                name="confidentialData"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    size="small"
                    multiple
                    options={confidentialInformationEntitiesToIdentify}
                    fullWidth
                    disableCloseOnSelect
                    filterSelectedOptions
                    freeSolo={false}
                    getOptionLabel={(option) => option.description}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    value={field.value}
                    onChange={(_, value, reason) => {
                      validatePersonalAndConfidentialData(
                        watch("personalData"),
                        value
                      );
                      if (value.some((v) => v.id === "selectAll")) {
                        field.onChange(
                          confidentialInformationEntitiesToIdentify
                        );
                      } else {
                        field.onChange(value);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filter = createFilterOptions<{
                        id: string;
                        description: string;
                      }>();
                      const filtered = filter(options, params);
                      if (
                        field.value.length ===
                        confidentialInformationEntitiesToIdentify.length
                      ) {
                        return [];
                      } else {
                        return [
                          { id: "selectAll", description: "Select All" },
                          ...filtered,
                        ];
                      }
                    }}
                    renderOption={(props, option, state) => (
                      <>
                        {option.id === "selectAll" ? (
                          <div key={option.id}>
                            <li {...props}>{option.description}</li> <Divider />
                          </div>
                        ) : (
                          <li key={option.id} {...props}>
                            {option.description}
                          </li>
                        )}
                      </>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label={"Confidential Information"}
                        error={isSubmitted && !!errors.confidentialData}
                      />
                    )}
                  />
                )}
              />
            </FormControl>

            {/*  @ts-ignore */}
            {isSubmitted && errors.confidentialData?.message && (
              <>
                {/*  @ts-ignore */}
                <ErrorHelper helperText={errors.confidentialData?.message} />
              </>
            )}

            <FormControl fullWidth size="small" sx={{ gap: 1 }}>
              <Typography>Select pages to process</Typography>
              <Controller
                name="selectedPages"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    size="small"
                    options={filePagesOptions}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) => option === value}
                    value={field.value}
                    onChange={(_, data) => field.onChange(data)}
                    renderInput={(params) => (
                      <TextField {...params} label="File Pages" />
                    )}
                  />
                )}
              />

              {selectedPages === "Customized" && (
                <Controller
                  name="customSelectedPages"
                  control={control}
                  rules={{
                    pattern: {
                      value: /^(\d+(-\d+)?)(,\s*\d+(-\d+)?)*$/,
                      message: "Invalid format. Use e.g. 1-3, 5, 7-10",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Custom File Pages"
                      placeholder="e.g. 1-3, 5, 7-10"
                      size="small"
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        validateCustomFilePages(e.target.value);
                      }}
                      error={isSubmitted && !!errors.customSelectedPages}
                      helperText={
                        isSubmitted && errors.customSelectedPages?.message
                      }
                    />
                  )}
                />
              )}
            </FormControl>

            <FormControl fullWidth size="small" sx={{ gap: 1 }}>
              <Typography>
                Specify the time of day when you want the NLP processing
              </Typography>
              <Controller
                name="dateTime"
                control={control}
                render={({ field }) => (
                  <MobileDateTimePicker
                    {...field}
                    inputFormat="YYYY/MM/DD hh:mm a"
                    mask="____/__/__ __:__ _M"
                    minDateTime={dayjs().add(1, "hour")}
                    renderInput={(params) => (
                      <TextField {...params} size="small" label="Date & Time" />
                    )}
                  />
                )}
              />
            </FormControl>
          </Box>
          <div style={{ textAlign: "center" }}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={hideModal}
              style={{ marginRight: 40 }}
            >
              CANCEL
            </Button>
            <Button
              type="button"
              color="secondary"
              variant="contained"
              onClick={handleSubmit(onSubmitForm)}
            >
              SUBMIT
            </Button>
          </div>
          <br />
        </form>
      </LocalizationProvider>
    </div>
  );
}
