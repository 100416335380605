import { Button, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { ButtonSpinner } from "../../components/button-spinner";
import { ErrorBox } from "../../components/error-box";
import { NavBar } from "../../components/navBar";
import { useAuthService } from "../../contexts/auth-context";
import { useValidation } from "../../hooks/validation";
import { AuthentificationModal } from "../modals/auth-modal";
import './login.scss';
import './signup.scss';
import useTimer from "./timerHook";
import './two-factor-code.scss';


export function TwoFactorAuthCode() {
    const [expiryTime] = useState(new Date(new Date().getTime() + 3 * 60 * 1000));
    const [attemptCount, setAttemptCount] = useState(0);
    const [codes] = useState<string[]>(new Array(6).fill(""));
    const [submitted, setSubmitted] = useState(false);
    const authContext = useAuthService();
    const [isCodeInvalid, setIsCodeInvalid] = useState<boolean>();
    const { signInExpressions } = useValidation();
    const lastDigit = useRef(false);
    const firstTextFieldRef = useRef<HTMLInputElement>(null!);
    const secondTextFieldRef = useRef<HTMLInputElement>(null!);
    const thirdTextFieldRef = useRef<HTMLInputElement>(null!);
    const forthTextFieldRef = useRef<HTMLInputElement>(null!);
    const fifthTextFieldRef = useRef<HTMLInputElement>(null!);
    const sixthTextFieldRef = useRef<HTMLInputElement>(null!);
    const refs = [firstTextFieldRef, secondTextFieldRef, thirdTextFieldRef, forthTextFieldRef, fifthTextFieldRef, sixthTextFieldRef];
    const location = useLocation();
    const email = location.state as string;

    const {
        totalSeconds,
        seconds,
        minutes,
      } = useTimer({ expiryTimestamp:expiryTime,autoStart:true })


    const { register, handleSubmit, formState: { errors }, setValue } = useForm<any>();
    const onSubmit: SubmitHandler<any> = async (data) => {
        const codes = [];
        for (let code in data) {
            codes.push(data[code]);
        }
        setSubmitted(true);
        const verifyCode = await authContext.verifyMultiFactorAuthCode(codes.join(''), attemptCountHandler, attemptCount);
        setSubmitted(false);
        setIsCodeInvalid(!verifyCode);
    }

    const attemptCountHandler=()=>{
        setAttemptCount(prev=>prev+1)
    }

    useEffect(() => {
        refs[0].current.focus();
        refs[0].current.addEventListener('paste', pasteAuthCode);
        for(let i = 0; i < 6; i++) {
            refs[i].current.addEventListener('keyup', keyPressCheck);
        }
    }, [])

    const pasteAuthCode = (ev: ClipboardEvent) => {
        ev.preventDefault();
        if (ev === null || ev.clipboardData === null) {
            return;
        }
        const pastedText = ev.clipboardData.getData('text').trim();
        refs.forEach((ref, index) => {
            setValue(`${index}`, pastedText[index]);
        });
        refs[5].current.focus();
    }

    const keyPressCheck = (ev: KeyboardEvent) => {
        let lastNumber = 0;
        refs.forEach(ref => {
            if (ref.current.value && ref.current.value !== '') {
                lastNumber++;
            }
        });
        if (ev.code === "Backspace") {
            if(lastNumber === 5 && lastDigit.current) {
                lastDigit.current = false;
                return;
            }
            setValue(`${lastNumber - 1}`, '');
            refs[lastNumber - 1].current.focus();
        } else {
            if (lastNumber < 6) {
                refs[lastNumber].current.focus();
            } else {
                lastDigit.current = true;
            }
        }
    }


    return <div>
        <NavBar />
        <div className='box-container'>
            <AuthentificationModal>
                <div className="content-box">
                    <span className="two-factor-title" >Two-step verification</span>
                    {isCodeInvalid ? <div className="error-box">
                        <ErrorBox
                            errorTitle={<span>{`${attemptCount < 4 ? 'The security code you entered is not valid.' : 'Your log in attempt exceed 4 times.'}`}</span>}
                            errorText={<span>{`${attemptCount < 4 ? ' Please try again with the correct verification code or click' : ''}`} <b>Go back to login page</b> to log in again and get a new code to your email address.</span>}
                        />
                    </div> : totalSeconds>0 ? <p className="two-factor-details">
                            A 6-digit code has been sent to <b>{email}</b>
                        </p> :
                        <div className="error-box">
                            <ErrorBox
                                errorTitle={<span>The code has expired.</span>}
                                errorText={<span>Please click <b>Go back to login page</b> to log in again and get a new code to your email address.</span>}
                            />
                        </div>
                    }
                    <p className="security-code-header">
                        Enter the security code
                    </p>
                    <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ flexDirection: "row" }}>
                            {codes.map((item, index) => <TextField
                                    error={!!errors[String(index)]}
                                    autoComplete='off'
                                    key={index}
                                    variant="outlined"
                                    inputRef={refs[index]}
                                    id="outlined-error-helper-text"
                                    {...register(String(index), { required: true, pattern: signInExpressions.isDigitExpression })}
                                    inputProps={{ maxLength: 1, style: { textAlign: "center", fontSize: 25, padding: "10px 14px" } }}
                                    style={{ width: 48, marginLeft: index ? 8 : 0 }}
                                />
                            )}
                        </div>
                        <p className="tooltips-wrapper">
                            <li className="main-li">
                                The code will expire in <b className={totalSeconds <= 0 ? "error-text" : ""}>{`${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`}</b>
                            </li>
                            <li className="main-li resend-code">
                                Didn't receive the code or the code has expired? <a href="/login" className="hyperlink-text">Go back to login page</a> to log in again and get a new code.
                            </li>
                        </p>
                        <Button disabled={submitted || attemptCount >= 4 || totalSeconds <= 0} type="submit" variant="contained" color="secondary" >
                            VERIFY {submitted && <ButtonSpinner />}
                        </Button>
                    </form>
                </div>

            </AuthentificationModal>
        </div>
    </div>
}
